<template>
  <base-missing-data-page :url-img="$options.imageDataMissing">
    <template v-if="!isArchive" #message>
      {{ $t('contractors.missing_message') }}
    </template>

    <template v-else #message>
      {{ $t('contractors.archived_missing_message') }}
    </template>
  </base-missing-data-page>
</template>

<script>
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-services.svg';

export default {
  name: 'ContractorsMissingData',

  components: { BaseMissingDataPage },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  imageDataMissing,
};
</script>
